import PromiseWindow from './PromiseWindow.js';

const BASE_URL = 'https://app.truto.one'

const createIframe = (url, onResponse) => {
    const iframe = document.createElement('iframe');
    const eventListener = (event) => {
        if (['http://localhost:5173', 'https://app.truto.one'].includes(event.origin)) {
            if (event.data.error && event.data.is_truto) {
                const err = new Error(event.data.error);
                Object.keys(event.data).forEach((key) => {
                    err[key] = event.data[key];
                })
                window.removeEventListener('message', eventListener);
                iframe.remove();
                onResponse(err);
            }
            if (event.data === 'closed' || event.data.is_truto) {
              window.removeEventListener('message', eventListener);
              iframe.remove();
              onResponse(event.data);
            }
        }
    }
    window.addEventListener('message', eventListener);
    iframe.src = url
    iframe.style.position = 'fixed';
    iframe.style.top = '0';
    iframe.style.left = '0';
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';
    iframe.style.background = 'rgba(0, 0, 0, 0.8)'
    iframe.id = 'truto-link-iframe';
    document.body.appendChild(iframe);
}

const authenticate = (linkToken, options = {}) => {
    const iframe = options.iframe !== false;
    const sameWindow = options.sameWindow === true;
    const url = new URL(`${options.baseUrl || BASE_URL}/connect-account`)
    url.searchParams.append('link_token', linkToken)
    if (options.integration) {
      url.searchParams.append('integration', options.integration)
    }
    if (options.noBack) {
      url.searchParams.append('no_back', options.noBack)
    }
    if (options.authFormat) {
      url.searchParams.append('auth_format', options.authFormat)
    }
    if (options.skipRapidForm) {
      url.searchParams.append('skip_rapid_form', 'true')
    }
    if (iframe && !sameWindow) {
      url.searchParams.append('iframe', 'true')
    }
    if (options.integrations) {
      url.searchParams.append('integrations', options.integrations.join(','))
    }

    if (options.sameWindow) {
      window.location.href = url.toString();
      return
    }

    if (iframe) {
        return new Promise((resolve, reject) => {
            createIframe(url.toString(), (result) => {
                if (result === 'closed') {
                    return reject(result);
                }
                if (result.error) {
                    return reject(result);
                }
                resolve(result)
            })
        });
    }

    const promiseWindow = new PromiseWindow(url.toString(), {
        windowName: 'Truto Connect',
        width: options.width || 700,
        height: options.height || 800
    })

    return new Promise((resolve, reject) => {
        promiseWindow.open().then((result) => {
            if (result.error) return reject(result);
            resolve(result)
        }, function (error) {
            reject(error)
        })
    });
}

export const rapidForm = (integratedAccountToken, options = {}) => {
  const iframe = options.iframe !== false;
  const sameWindow = options.sameWindow === true;
  const url = new URL(`${options.baseUrl || BASE_URL}/connect-account/post-connect`)
  url.searchParams.append('integrated_account_session_token', integratedAccountToken)

  if (options.integration) {
    url.searchParams.append('integration', options.integration)
  }
  if (iframe) {
    url.searchParams.append('iframe', 'true')
  }
  if (options.additionalContext) {
    url.searchParams.append('truto_additional_context', JSON.stringify(options.additionalContext))
  }
  if (options.preventDeselect) {
    url.searchParams.append('truto_prevent_deselect', JSON.stringify(options.preventDeselect))
  }
  if (options.disabledFields) {
    url.searchParams.append('truto_disabled_fields', JSON.stringify(options.disabledFields))
  }

  if (options.sameWindow) {
    window.location.href = url.toString();
    return
  }

  const promiseWindow = new PromiseWindow(url, {
      windowName: 'Truto RapidForm',
      width: options.width || 700,
      height: options.height || 800
  })

  if (iframe) {
      return new Promise((resolve, reject) => {
          createIframe(url, (result) => {
              if (result === 'closed') {
                  return reject(result);
              }
              if (result.error) {
                  return reject(result);
              }
              resolve(result)
          })
      });
  }

  return new Promise((resolve, reject) => {
      promiseWindow.open().then((result) => {
          if (result.error) return reject(result);
          resolve(result)
      }, function (error) {
          reject(error)
      })
  });
}

export default authenticate;
